<div class="relative overflow-hidden">
  <div class="imgbox">
    <img src="assets/BrownDawgs.png" alt="Image" class="center-fit">
  </div>
  <div class="text-center my-6 relative">
    <div class="text-6xl text-primary font-bold mb-4">{{ data?.nextDate }}</div>
    <p class="text-3xl">{{ data?.nextLocation }}</p>
  </div>
  <div *ngIf="lastMonthResults != null">
    <div class="text-center my-6 relative">
      <div class="text-6xl text-primary font-bold mb-4">{{ lastMonthName }} Results</div>
    </div>
    <table class="center-fit text-2xl">
      <thead>
        <th class="text-left">Name</th>
        <th class="text-left p-1 pl-3">Score</th>
      </thead>
      <tbody>
      <tr *ngFor="let player of lastMonthResults" class="tooltip">
        <td class="x">
          <span class="tooltiptext">{{ player.name }} = {{ player.explanation }}</span>{{ player.name }}</td>
        <td class="p-1 pl-3">{{ player.score }}</td>
      </tr>
      </tbody>
    </table>

    <div *ngIf="monthlyResults.length > 1">
      <div class="text-center my-6 relative">
        <div class="text-6xl text-primary font-bold mb-4">Totals</div>
      </div>
      <table class="center-fit text-2xl">
        <thead>
        <th class="text-left">Name</th>
        <th class="text-left p-1 pl-3">Score</th>
        </thead>
        <tbody>
        <tr *ngFor="let player of totalResults; index as i" class="tooltip">
          <td class="x" [ngClass]="{'bottomBold': i == 7}">
            <span class="tooltiptext">{{ player.name }} = <span [innerHTML]="player.explanation"></span></span>{{ player.name }}</td>
          <td class="p-1 pl-3" [ngClass]="{'bottomBold': i == 7}">{{ player.score }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
