import {Component, Injectable, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";

interface PlayerScore {
  name: string;
  score: number;
  explanation: string;
}

interface TournamentResult {
  signouts: string[];
  highHand: string;
  bounty: string;
  badbeat: string|null;
}

interface PlayerScoreMonth {
  month: string,
  playerScore: PlayerScore;
}

interface PlayerScoreTally {
  name: string;
  scores: PlayerScoreMonth[];
  lowestIndex: number;
  secondLowestIndex: number;
}

function placeString(placeScore: number): string {
  const place = 5 - placeScore;
  let s = place.toString();
  if (place == 0) {
    s = "1st";
  } else if (place == 2) {
    s += "nd";
  } else if (place == 3) {
    s += "rd";
  } else {
    // place == 4
    s += "th";
  }
  return s;
}
export interface Data {
  nextDate: String;
  nextLocation: String;
  monthlyResults: TournamentResult[];
}

function results(result: TournamentResult): PlayerScore[] {
  const scores: PlayerScore[] = [];
  let signoutScore = result.signouts.length;
  let placeScore = 5;
  let highHandFound = false;
  let bountyFound = false;
  let badbeatFound = false;

  for (let i = result.signouts.length - 1; i >= 0; i--) {
    let signoutName = result.signouts[i];
    let score = signoutScore--;
    let explanation = score.toString();

    if (placeScore != 0) {
      explanation += ` + ${placeScore} (${placeString(placeScore)})`
      score += placeScore;
      placeScore -= 1;
      if (placeScore == 4) {
        // 1st place gets an extra point, so bonus points are (5, 3, 2, 1)
        placeScore -= 1;
      }
    }
    if (signoutName == result.highHand) {
      if (highHandFound) {
        console.log("Found high hand twice!");
        continue;
      }
      highHandFound = true;
      explanation += ` + 5 (high hand)`
      score += 5;
    }
    if (signoutName == result.bounty) {
      if (bountyFound) {
        console.log("Found bounty twice!");
        continue;
      }
      bountyFound = true;
      explanation += ` + 5 (bounty)`
      score += 5;
    }
    if (signoutName == result.badbeat) {
      badbeatFound = true;
      explanation += ` + 2 (badbeat)`;
      score += 2;
    }
    scores.push({
      name: signoutName,
      score: score,
      explanation: explanation
    });
  }

  if (highHandFound == false) {
    console.error("No high hand found!");
  }
  if (result.bounty != null && bountyFound == false) {
    console.error("Bounty name not found!");
  }
  if (result.badbeat != null && badbeatFound == false) {
    console.error("Badbeat name not found!");
  }

  scores.sort(function(a, b) {
    const as = a.score;
    const bs = b.score;
    return as < bs ? 1 : (as > bs ? -1 : 0);
  });

  return scores;
}


@Injectable({providedIn: 'root'})
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  monthNames: string[] = [
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
    "January",
    "February",
    "March",
    "April",
    "May"
  ]
  monthlyResults: PlayerScore[][] = [];
  lastMonthResults: PlayerScore[]|null = null;
  lastMonthName = "";
  totalResults: PlayerScore[] = [];

  data?: Data;

  public constructor(private http: HttpClient) {}

  public ngOnInit() {
    const url: string = "/data/data.json";
    this.http.get<Data>(url).subscribe((response) => {
      this.data = response;
      this.monthlyResults = response.monthlyResults.map(v => results(v));
      this.lastMonthResults = this.monthlyResults.length == 0 ? null : this.monthlyResults[this.monthlyResults.length - 1];
      this.lastMonthName = this.monthlyResults.length == 0 ? "" : this.monthNames[this.monthlyResults.length - 1];
      this.totalResults = this.calculateTotalResults();
    });
  }

  private calculateTotalResults(): PlayerScore[] {
    const accumulators: { [key: string]: PlayerScoreTally } = {};

    for (let i = 0; i < this.monthlyResults.length; i++) {
      const monthResults = this.monthlyResults[i];
      for (let j = 0; j < monthResults.length; j++) {
        const playerScore = monthResults[j];

        let playerAccumulator: PlayerScoreTally;
        if (playerScore.name in accumulators) {
          playerAccumulator = accumulators[playerScore.name];
        } else {
          playerAccumulator = {
            name: playerScore.name,
            scores: [],
            lowestIndex: -1,
            secondLowestIndex: -1
          };
          accumulators[playerScore.name] = playerAccumulator;
        }

        const monthScore: PlayerScoreMonth = {
          month: this.monthNames[i],
          playerScore: playerScore
        };
        playerAccumulator.scores.push(monthScore);
        const monthIndex = playerAccumulator.scores.length - 1;
        if (playerAccumulator.lowestIndex == -1 || monthScore.playerScore.score < playerAccumulator.scores[playerAccumulator.lowestIndex].playerScore.score) {
          playerAccumulator.secondLowestIndex = playerAccumulator.lowestIndex;
          playerAccumulator.lowestIndex = monthIndex;
        }
      }
    }

    const totalScores: { [key: string]: PlayerScore } = {};
    for (const playerName in accumulators) {
      const playerAccumulator = accumulators[playerName];
      const playerTotal: PlayerScore = {
        name: playerName,
        score: 0,
        explanation: ''
      }
      totalScores[playerName] = playerTotal;
      const skipLowest: boolean = playerAccumulator.scores.length > 9;
      const skipSecondLowest: boolean = playerAccumulator.scores.length > 10;

      for (let i = 0; i < playerAccumulator.scores.length; ++i) {
        if (playerTotal.explanation.length != 0) {
          playerTotal.explanation += ' + ';
        }

        let skip = skipLowest && i == playerAccumulator.lowestIndex || skipSecondLowest && i == playerAccumulator.secondLowestIndex;
        let monthScore = playerAccumulator.scores[i];

        if (skip == false) {
          playerTotal.score += monthScore.playerScore.score;
        }
        if (skip) {
          playerTotal.explanation += `<s>`
        }
        playerTotal.explanation += `${monthScore.playerScore.score} (${monthScore.month})`
        if (skip) {
          playerTotal.explanation += `</s>`
        }
      }
    }

    return Object.values(totalScores).sort((a: PlayerScore, b: PlayerScore) => b.score - a.score);
  }
}
